import TYPES from '@/types';
import { maxLengthGoalNameRule } from '@/vue-app/utils/form-rules';

// Application
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';

// Domain
import {
  InvestmentProductsStateManager,
} from '@/modules/flagship/catalogs/investment-products/domain/state/investment-products-state-manager';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type GoalOption = {
  id: string;
  label: string;
  icon: string;
  image_src: string;
  product_name: string;
}

export default class GoalTypeSelectorViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.GET_CUSTOM_GOAL_TYPES_QUERY)
  private readonly get_custom_goal_type!: GetCustomGoalTypesQuery;

  @Inject(TYPES.INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly product_state_manager!: InvestmentProductsStateManager;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.goal-type-selector';

  private readonly view: any;

  custom_goal_types: Array<CustomGoalTypesEntity> = [];

  personal_goal = '';

  other_personal_goal = '';

  other_custom_goal_type: CustomGoalTypesEntity = {
    id: '',
    icon_name: '',
    name: '',
    label: '',
    description: '',
    created_at: '',
    updated_at: '',
  };

  selected_index = -1;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  constructor(view: any) {
    this.view = view;
  }

  get is_continue_btn_disabled() {
    return this.investor_goal_state.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  options: Array<GoalOption> = [];

  input_rules = {
    other_goal_name: [(value: string) => maxLengthGoalNameRule(26, value)],
  };

  validateAlreadyCreatedGoals = () => {
    if (!this.investor_goal_state.emergency_fund.already_created) {
      this.options.push({
        id: 'emergency_fund',
        label: this.translate('emergency_fund'),
        icon: 'icon-retirement-white.svg',
        image_src: 'logo_sowos_pocket.svg',
        product_name: 'sowos_pocket',
      });
    }
    if (!this.investor_goal_state.retirement_fund.already_created) {
      this.options.push({
        id: 'retirement_fund',
        label: this.translate('retirement_fund'),
        icon: 'icon-fund-white.svg',
        image_src: 'logo_sowos_wealth.svg',
        product_name: 'sowos_wealth',
      });
    }
  }

  loadCustomGoalTypes = async () => {
    try {
      const valid_types = ['a_special_trip', 'house_initial_deposit', 'buy_a_car'];
      const goal_types = await this.get_custom_goal_type.execute();
      this.custom_goal_types = goal_types.filter(
        (goal_type) => valid_types.indexOf(goal_type.name) >= 0,
      );
      const other_goal_type = goal_types.find((goal_type) => goal_type.name === 'other');
      if (other_goal_type) {
        this.other_custom_goal_type = other_goal_type;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_custom_goal_types'));
    }
  };

  updateAssociatedProduct = (product_name: string) => {
    const product_associated = this.product_state_manager.state.items.find(
      (product) => product.name === product_name,
    );
    if (product_associated) {
      this.investor_goal_state.associated_product_id = product_associated.id;
    }
  }

  selectOption = (index: number) => {
    if (this.selected_index !== -1 && this.selected_index < 2) {
      this.options[this.selected_index]
        .icon = this.options[this.selected_index].icon.replace('.svg', '-white.svg');
    }
    this.selected_index = index;
    this.options[index].icon = this.options[index].icon.replace('-white', '');
    this.create_investor_goal_state_manager.state.investor_goal_type = this.options[index].id;
    if (this.options[index].id === 'emergency_fund') {
      this.investor_goal_state.investor_goal.goal_name = 'Fondo de Emergencia';
    } if (this.options[index].id === 'retirement_fund') {
      this.investor_goal_state.investor_goal.goal_name = 'Fondo para el retiro';
    } else {
      this.investor_goal_state.investor_goal.goal_name = 'Meta Personalizada';
    }
    this.updateAssociatedProduct(this.options[index].product_name);
    this.other_personal_goal = '';
  }

  selectCustomGalType = (option: CustomGoalTypesEntity) => {
    this.create_investor_goal_state_manager.state.investor_goal_type = 'custom';
    this.investor_goal_state.custom_investor_goal.custom_goal_type = option;
    this.investor_goal_state.investor_goal.goal_name = option.label;
  }

  changeOtherPersonalGoal = () => {
    if (this.create_investor_goal_state_manager.state.investor_goal_type !== 'custom') {
      this.investor_goal_state.custom_investor_goal.custom_goal_type = this.other_custom_goal_type;
    }
    this.create_investor_goal_state_manager.state.investor_goal_type = 'custom';
    this.investor_goal_state.investor_goal.goal_name = this.other_personal_goal;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    await this.validateAlreadyCreatedGoals();
    await this.loadCustomGoalTypes();
    this.investor_goal_state.is_loading = false;
  }
}
