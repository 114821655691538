




























































































































import { Component, Vue } from 'vue-property-decorator';
import GoalTypeSelectorViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/goal-type-selector-view-model';

@Component({
  name: 'GoalTypeSelector',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class GoalTypeSelector extends Vue {
  view_model = Vue.observable(
    new GoalTypeSelectorViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
